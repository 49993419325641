import React, { useEffect, useState } from 'react';
import { GET_ALL_PRODUCTS } from '../../queries';
import { client } from './hydrogenClient';
import { AddToCartButton, Money, ProductProvider } from '@shopify/hydrogen-react';

const ProductList = () => {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchProducts() {
      const res = fetch(client.getStorefrontApiUrl(), {
        body: JSON.stringify({
          query: GET_ALL_PRODUCTS,
        }),
        headers: client.getPublicTokenHeaders(),
        method: 'POST',
      });
      const json = await (await res).json();
      setData(json.data);
    }
    fetchProducts();
  }, [])

  // TODO: Better empty state handling
  if (!data) {
    return (
      <div>No products found</div>
    )
  }
  
  function getProductGrid() {
    const products = data.products.edges.map(edge => edge.node);
    return (
      <div style={gridStyle}>
        {products.map((product) => {
          const variantId = product.variants.edges[0].node.id;
          const imgSrc = product.images.edges[0].node.url;
          return (
            <ProductProvider data={product} key={product.id}>
              <div key={product.id} style={productCardStyles(imgSrc)}>
                <h3>{product.title}</h3>
                <p>{product.description}</p>
                <p>
                  <Money data={product.variants.edges[0].node.priceV2} />
                </p>
                <AddToCartButton
                  className='button button-green'
                  variantId={variantId}
                >
                  Add to cart
                </AddToCartButton>
              </div>
            </ProductProvider>
          );
        })}
      </div>
    );
  }

  const gridStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  };
  
  const productCardStyles = (imageUrl) => {
    return {
      flex: '1 1 300px',
      height: '300px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      // Darken overlay
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      backgroundBlendMode: 'darken',
    };
  };

  return getProductGrid();
};

export default ProductList;
