import React from 'react';
import { CartCheckoutButton, CartCost, CartLineProvider, CartLineQuantityAdjustButton, useCart } from '@shopify/hydrogen-react';

const Cart = () => {
  const { lines } = useCart();
  const emptyCart = lines.length === 0;

  const ItemizedList = () => {
    return lines.map((line) => (
      <CartLineProvider line={line} key={line.id}>
        <div>{line.merchandise.product.title}</div>
        <div>{line.quantity}</div>
        <CartLineQuantityAdjustButton adjust="increase">
          Increase
        </CartLineQuantityAdjustButton>
        <CartLineQuantityAdjustButton adjust="decrease">
          Decrease
        </CartLineQuantityAdjustButton>
        <CartLineQuantityAdjustButton adjust="remove">
          Remove
        </CartLineQuantityAdjustButton>
      </CartLineProvider>
    ));
  }

  return (
    <div>
      <ItemizedList />
      <div>Subtotal: <CartCost /></div>
      <CartCheckoutButton className={`button button-${emptyCart ? 'gray' : 'green'}`} disabled={emptyCart}>Checkout</CartCheckoutButton>
    </div>
  );
};

export default Cart;
