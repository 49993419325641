import React from "react";
import Layout from "../../components/Layout";
import Cart from '../../components/shop/Cart';
import ProductList from '../../components/shop/ProductList';
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react';

import "../../style/shop.sass"

const Shop = () => {  
  return (
    <ShopifyProvider
      storeDomain={process.env.GATSBY_SHOPIFY_STORE_DOMAIN}
      storefrontToken={process.env.GATSBY_SHOPIFY_STOREFRONT_PUBLIC_TOKEN}
      storefrontApiVersion="2024-04"
      countryIsoCode="CA"
      languageIsoCode="EN"
    >
      <CartProvider
        onLineAddComplete={() => {
          console.log('A line has been added');
        }}
      >
        <Layout>
          <div className="container blue-bg">
            <div className="section">
              <h1>Shop</h1>
              <div className="shop-ui">
                <ProductList />
                <Cart />
              </div>
            </div>
          </div>
        </Layout>
      </CartProvider>
    </ShopifyProvider>
  )
}

export default Shop;
